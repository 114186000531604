import React, { useState } from "react";
import Logo from "../../Assets/logo.png";
import ArrowRight from "../../Assets/arrowRight.svg";
import Damas from "../../Assets/damas.svg";
import Livpure from "../../Assets/liverpure.svg";
import Lifestyle from "../../Assets/lifestyle.svg";
import Lemill from "../../Assets/leMill.svg";
import Uber from "../../Assets/uber.svg";
import Decathlon from "../../Assets/decathlon.svg";
import Nalli from "../../Assets/nalli.svg";
import Pestle from "../../Assets/pestel.svg";
import { Modal } from "antd";
import "./HersoSection.scss";

const HersoSection = () => {
	const [openForm, setOpenForm] = useState(false);
	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		const formData = {
			name,
			email,
			number,
			message,
		};

		try {
			const response = await fetch(
				"https://script.google.com/macros/s/AKfycbzt3FJPdqagzR31kkb8TmaZg4fO4BUH_scQDjT3QN8xdFn1vwLbf0Eg8ILAKeOeVPCV/exec",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json", // Ensure you're sending JSON
					},
					mode: "no-cors",
					body: JSON.stringify(formData), // Convert form data to JSON
				}
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setOpenForm(false);
			setName("");
			setEmail("");
			setMessage("");
			alert(data.msg); // Show success message from the response
		} catch (err) {
			console.error("There was a problem with the fetch operation:", err);
			alert("Submission successful."); // Handle failure
			setOpenForm(false);
			setName("");
			setEmail("");
			setMessage("");
		}
	};

	const handleClick = (event) => {
		// Retrieve the content of the child <p> element
		const paragraphText = event.currentTarget.querySelector("p").innerText;
		setMessage(paragraphText);
	};

	// Inline styles
	const formStyle = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "fit-content",
		gap: "1.4rem",
		width: "100%",
		borderRadius: "1rem",
		backgroundColor: "#ffffff",
		padding: "0.5rem 0",
	};

	const inputFieldsStyle = {
		display: "flex",
		flexDirection: "column",
		gap: "0.5rem",
		width: "100%",
		alignItems: "flex-start",
	};

	const inputStyle = {
		border: "none",
		borderRadius: "0.4rem",
		width: window.innerWidth <= 768 ? "90%" : "94%",
		padding: "1rem",
		backgroundColor: "#f6f4f4",
	};

	const doubleFieldStyle = {
		display: "flex",
		flexDirection: "row",
		gap: "1.4rem",
		alignItems: "center",
	};

	const contactBtnStyle = {
		fontSize: "1rem",
		fontWeight: "600",
		lineHeight: "1.21rem",
		backgroundColor: "#225daa",
		color: "#ffffff",
		width: "100%",
		padding: "1rem",
		borderRadius: "10px",
		border: "none",
		cursor: "pointer",
	};

	return (
		<div className="NVHeroSectionContainer">
			<div className="NavBar">
				<img src={Logo} alt="Logo" />
			</div>
			<div className="NVBody">
				<h1>
					software product <br /> development on steroids
				</h1>
				<h3>what would you like to build?</h3>
				<div className="CardsContainer">
					<div className="card" onClick={handleClick}>
						<p>I want to build a website for my startup.</p>
					</div>
					<div className="card" onClick={handleClick}>
						<p>
							I have an idea of SaaS product in eCommerce industry, can you
							build it for me?
						</p>
					</div>
					<div className="card" onClick={handleClick}>
						<p>
							I want to build a consumer mobile app for healthcare industry, can
							you build it for me?
						</p>
					</div>
					<div className="card" onClick={handleClick}>
						<p>
							I want to enable AI capabilities across my software product line,
							can you do it?
						</p>
					</div>
				</div>
				<div className="AboutIputContainer">
					<input
						type="text"
						placeholder="tell us what would you like to build..."
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					<button onClick={() => setOpenForm(true)}>
						<img src={ArrowRight} alt="Arrow Right" />
					</button>
				</div>
				<div className="LogosContainer">
					<img src={Damas} alt="Damas" />
					<img src={Livpure} alt="Livpure" />
					<img src={Lifestyle} alt="Lifestyle" />
					<img src={Lemill} alt="Lemill" />
					<img src={Uber} alt="Uber" />
					<img src={Decathlon} alt="Decathlon" />
					<img src={Nalli} alt="Nalli" />
					<img src={Pestle} alt="Pestle" />
				</div>
			</div>
			<div className="footer">
				<p>© Atiiad Technologies Pvt Ltd 2024. All Rights Reserved</p>
			</div>
			<Modal
				open={openForm}
				onCancel={() => setOpenForm(false)}
				centered
				footer={false}
				closable={false}
			>
				<form onSubmit={handleSubmit} style={formStyle}>
					<div style={inputFieldsStyle}>
						<input
							type="text"
							placeholder="Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							style={inputStyle}
						/>
					</div>
					<div style={{ ...inputFieldsStyle, ...doubleFieldStyle }}>
						<input
							type="text"
							placeholder="Number"
							value={number}
							onChange={(e) => setNumber(e.target.value)}
							style={inputStyle}
						/>
						<input
							type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							style={inputStyle}
						/>
					</div>
					<div style={inputFieldsStyle}>
						<textarea
							rows={5}
							placeholder="Need of a solution"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							style={inputStyle}
						/>
					</div>
					<button type="submit" style={contactBtnStyle}>
						Submit
					</button>
				</form>
			</Modal>
		</div>
	);
};

export default HersoSection;
