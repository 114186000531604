import { BrowserRouter, Routes, Route } from "react-router-dom";
import HersoSection from "./Pages/HersoSection/HersoSection";

function router() {
	return (
		<div>
			<BrowserRouter>
				<Routes>
					<Route path="" element={<HersoSection />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default router;
